import React from 'react';
import Meta from '~features/Meta';

const NotFoundPage = (): JSX.Element => (
  <div>
    <Meta title="404: Not found" description="Page not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
  </div>
);

export default NotFoundPage;
